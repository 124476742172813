.button
{

	&.arrow
	{

		&.right{}
		&.left{}
		&.down{}
		&.up{}

		vertical-align: middle;

		padding: 0px;
		margin: 0px;

		height: 40px;

		background: none;
		color: $dark-gray;

		&::after
		{
			display: inline-block;
			content: url('../svg/button-arrow.svg');
			vertical-align: inherit;
			margin-left: .4rem;
			height: 30px;
			width: 30px;
		}

		h3, h4
		{
			display: inline;

			margin-bottom: 0px;
		}

	}

}