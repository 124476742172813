.sticky
{

	z-index: 999 !important;
	width: 100%;

	background-color: $white;

	@include transition(padding-bottom .3s, padding-top .3s, font-size .3s, height .3s);

@include breakpoint(small) {

	padding-top: 1rem;
	font-size: 1.21rem;

	a
	{
		width: 100%;
	}

	nav
	{
		font-weight: 200 !important;
	}

	#logo
	{
		min-width: 200px;
		height: 60px;
	}

	hr
	{
		margin-top:		1rem !important;
		border-width: 1px;
		border-color: $light-gray;
	}

	&.is-stuck hr
	{
		margin-top:	1rem;
		margin-bottom: 0rem;
		border-width: 1px;
		border-color: $light-gray;
	}

	&.is-anchored
	{
		position: fixed;
	}

	hr
	{
		margin-top:	2.5rem;
		margin-bottom:	0px;
		border-color: $white;
	}
	
}

@include breakpoint(xlarge) {

	padding-top: 2.5rem;
	font-size: 1.43rem;

	&.is-stuck
	{
		padding-top: 1rem;
		font-size: 1.21rem;
	}

	&.is-stuck nav
	{
		font-weight: 200 !important;
	}

	&.is-stuck #logo
	{
		min-width: 200px;
		height: 60px;
	}

	#logo
	{
		min-width: 300px;
		height: 90px;
	}

	hr
	{
		margin-bottom:	2rem;
		border-color: $white;
	}
}

}


nav
{	

	font-weight: 200;

	ul a
	{
		@include transition(color .3s);
	}

	ul
	{
		position: relative;
	}

	ul:hover
	{	

		a
		{
			color: $light-gray;	
		}		
	}

	a
	{
		color: $dark-gray;
	}

	li
	{

		&:hover > a
		{
			color: $dark-gray !important;
		}

		&:focus > a
		{
			color: $dark-gray !important;
		}
	}

	&.is-stuck #pagePointer
	{
		//border-width: 1px;
	}

	#logo
	{

	@include transition(height .3s, min-width .3s);

	min-width: 300px;
	height: 90px;

	margin-right: 1rem;

	background-image: url("../content/img/etlz-logo.png");
	background-repeat: no-repeat;
	background-size:100%;
	}

	#logo:hover ~ li a
	{
		color: $dark-gray;
	}

	#mainMenu
	{

		@extend .row;

		.submenu
		{
			border-top: 2px solid get-color(primary);
		}

		#lipoHeizkofferSubMenuLable
		{
			visibility: hidden;
			overflow: hidden;
			max-width: 0px;

			@include transition(max-width .3s, opacity .3s, visibility .3s);

			&.is-selected
			{
				max-width: 200px;
				visibility: visible;
			}

			a
			{
				padding-left: 0px;
			}

		}

		.active > a
		{
			background: none !important;
		}

	}

	.is-dropdown-submenu
	{

		display: block;
		visibility: hidden;
		//overflow: hidden;
		opacity: 0;

		min-width: rem-calc(315);
		
		@include transition(opacity .3s, visibility .3s);

		&.js-dropdown-active
		{
			opacity: 1;
			visibility: visible;
		}

		a:hover
		{
			background-color: rgb(250,250,250);
		}


	}

	#pagePointer
	{
		@include transition(margin-left .3s, top .3s, width .3s);
		
		height: 0px;
		width: 0px;

		position:absolute;
		top: 78%;

		border-bottom: 2px solid get-color(primary);

	}

}