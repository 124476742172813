body{

	::-moz-selection {
		color: $white;
		background: get-color(primary);
	}

	::selection {
		color: $white;
		background: get-color(primary);
	}
}

$pageWidth:(
	medium	: 90% !important,
	large	: 1000px !important,
	xlarge	: 1300px !important,
	xxlarge	: 1600px !important,
	);

main section, footer {

  @include breakpoint(medium) {
	
	width: map-get($pageWidth, medium);
	}

  @include breakpoint(large) {
  	width: map-get($pageWidth, large);
  }

  @include breakpoint(xlarge) {
  	width: map-get($pageWidth, xlarge);
  }

  @include breakpoint(xxlarge) {
  	width: map-get($pageWidth, xxlarge);
  }

}

section
{

@include breakpoint(small) {

	//margin-bottom: 6rem;

	hr:last-child
	{

		width: 100%;

		margin-top: 	4rem;
		margin-bottom: 	3rem;
	}

	h1:first-child
	{
		margin-top: 1rem;
		padding-bottom: 1rem;
	}

}//BREAKPOINT

@include breakpoint(xlarge) {

	//margin-bottom: 8rem;

	hr:last-child
	{
		margin-top: 	5.5rem;
		margin-bottom: 	2.5rem;
	}

	h1:first-child
	{
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

}//BREAKPOINT

}

nav ul:first-child
{

  @include breakpoint(medium) {
	
	width: map-get($pageWidth, medium);
	}

  @include breakpoint(large) {
  	width: map-get($pageWidth, large);
  }

  @include breakpoint(xlarge) {
  	width: map-get($pageWidth, xlarge);
  }

  @include breakpoint(xxlarge) {
  	width: map-get($pageWidth, xxlarge);
  }

}

.sticky hr
{

  @include breakpoint(medium) {
	
	width: map-get($pageWidth, medium);
	}

  @include breakpoint(large) {
  	width: map-get($pageWidth, large);
  }

  @include breakpoint(xlarge) {
  	width: map-get($pageWidth, xlarge);
  }

  @include breakpoint(xxlarge) {
  	width: map-get($pageWidth, xxlarge);
  }

}

ul
{
	list-style: circle;
}

footer
{
	padding-top: 50px;
	padding-bottom: 50px;
}

p
{

@include breakpoint(small) {
	font-size: rem-calc(20) !important;
	}

@include breakpoint(xlarge) {
	font-size: rem-calc(22.5) !important;

	}

}


main ul li
{

@include breakpoint(small) {
	font-size: rem-calc(20) !important;
	}

@include breakpoint(xlarge) {
	font-size: rem-calc(22.5) !important;

	}
}

h1
{
	strong
	{
		color:get-color(primary);
	}
}

h2
{
}

h3
{
	color: get-color(primary);
	font-weight: 500;
	text-transform: uppercase;
}

h4
{
}

h5
{
	margin-top: .7rem;
	margin-bottom: .35rem;
	font-weight: 400;
}

svg.red-arrow
{

	vertical-align: middle;

	//margin-bottom: -0.28rem;

	path
	{
		stroke: get-color(primary);
		stroke-width: 1.5px;
		fill: none;
	}
}

.has-tip:after
{
	content:"?";
	color: $light-gray;
	font-weight: 700;
	font-size: .7rem;
	vertical-align: super;
}

.has-tip:hover:after
{
	color: get-color(primary);//$dark-gray;
}

.important-text
{	
	font-weight: 500; 
	color: get-color(primary);
}