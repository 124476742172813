#zubehoer
{

	article
	{
		position: relative;
	}

	div.columns
	{
		padding: 0px;
	}

	.card
	{

	@include breakpoint(small) {
		height: rem-calc(620);
	}//BREAKPOINT

	@include breakpoint(xlarge) {
		height: rem-calc(700);
	}//BREAKPOINT
	
	}

	.description
	{
		overflow: hidden;
		height: rem-calc(260);
		
		&.small
		{
			height: rem-calc(216);
		}
	}

	.card-section:last-child
	{

	@include breakpoint(small) {

		z-index: 800;

		position: absolute;
		bottom: 0;
		left: 	0;
		right: 	0;

		padding-bottom: rem-calc(10);

		margin-bottom: 	0rem !important;
		margin-top: 	0rem !important;

		background-color: $white;
	}//BREAKPOINT

	@include breakpoint(xlarge) {
		padding-bottom: rem-calc(15);
	}//BREAKPOINT

		hr
		{
			margin-top: 0px;
			margin-bottom: 0px;

		@include breakpoint(small) {
			margin-bottom: rem-calc(10);
		}//BREAKPOINT

		@include breakpoint(xlarge) {
			margin-bottom: rem-calc(15);
		}//BREAKPOINT

		}

	}
}