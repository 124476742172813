#haendler
{
	h1:first-child
	{
		padding-bottom: 0rem;

		margin-bottom: 0rem;

	}

	hr.red
	{
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}

	.arrow-button
	{
		width: 100%;
		text-align: left;

	}

	svg
	{
		margin-top: 0.28rem;
	}

	padding-bottom: 6rem;

}