#start
{

	position: relative;
	
	.switchToButton
	{
		position: absolute;
		
		top: 72.5%;
		
		@include horizontal-center;

		@include transition(margin-top .3s);
		
		width: 300px;

		color: $dark-gray;

		svg
		{

			path
			{
				stroke: $dark-gray;
				stroke-width:1px;
			}
		}

		&:hover
		{
			margin-top: 0.25rem;
		}

		h4
		{
			font-weight: 200;
		}

		
	}

	hr:last-child
	{
		border-width: 0rem;
	}

}