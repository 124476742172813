@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transition-delay($args...) {
  -webkit-transition-delay: $args;
  -moz-transition-delay: $args;
  -ms-transition-delay: $args;
  -o-transition-delay: $args;
  transition-delay: $args;
}

@mixin rotate($deg,$origin-x,$origin-y){
    -ms-transform: rotate($deg); /* IE 9 */
    -ms-transform-origin: $origin-x $origin-y; /* IE 9 */
    -webkit-transform: rotate($deg); /* Chrome, Safari, Opera */
    -webkit-transform-origin: $origin-x $origin-y; /* Chrome, Safari, Opera */
    transform: rotate($deg);
    transform-origin: $origin-x $origin-y;

}

// $filename WITHOUT extension!
@mixin font-face($font-family,$font-weight,$font-style,$path,$filename)
{
	@font-face
	{
		font-family: $font-family;
		src: url(#{$path}#{$filename}.eot)  format('eot'),
			 url(#{$path}#{$filename}.woff)	format('woff'),
			 url(#{$path}#{$filename}.ttf)	format('truetype'),
			 url(#{$path}#{$filename}.svg)	format('svg');
		font-weight: $font-weight;
		font-style: $font-style;
	}
}