
h1 .size-badge
{

	display: inline-block;

@include breakpoint(small) {

	height: rem-calc(42);
	width: 	rem-calc(42);
}//BREAKPOINT

@include breakpoint(xlarge) {

	height: rem-calc(48);
	width: 	rem-calc(48);
}//BREAKPOINT

	padding-left: rem-calc(1);

	font-weight: 300;
	text-align: center;
	line-height: 1.15;

	color:$white;
	background-color: get-color(primary);


}

button, .arrow-button
{
	@extend h4;
	display: block;
	
	&:hover
	{
		cursor: pointer;
	}

}

.arrow-button
{

	white-space: nowrap;
	margin-bottom: 0px;

	height: 40px;

	font-weight: 300;

	@include transition(margin-right .3s, margin-left .3s);

	&:hover
	{	
		margin-right: -0.25rem;
		margin-left: 0.25rem;
	}

	&.no-hover:hover
	{
		margin-right: 0px !important;
	}

	&:hover > .options, &:active > .options
	{

		@include transition-delay(0s);

		opacity: 1;

		margin-right: 0px;
		margin-left: 0px;
	}

	span
	{
		vertical-align: middle;
	}

	.options
	{
		display: inline-block;
		overflow: hidden;
		opacity: 0;

		height: 40px;

		margin-right: -150px;
		margin-left: 50px;

		@include transition(margin-right .6s, margin-left .6s, opacity .6s);
		@include transition-delay(1s);

		div
		{
			display: inline-block;
		
		@include breakpoint(small) {
			height: rem-calc(34);
			width: rem-calc(34);
			margin-top: rem-calc(4);
		}//BREAKPOINT

		@include breakpoint(xlarge) {
			height: 40px;
			width: 40px;
			margin-top: 0rem;
		}//BREAKPOINT


			text-align: center; //middle!!!???

			&:hover
			{
				//border: 1px solid $white;
				background-color: get-color(primary);
				color: $white;
				font-weight: 500;
			}

		}

	}

	a
	{
		display:block;
		width: 80%;
	}

	svg
	{
		width: 20px;
		height: 30px;
		margin-bottom: -0.28rem;

		path
		{
			stroke: get-color(primary);
			stroke-width: 1.5px;
			fill: none;
		}
	}

}


hr.red
{
	border-bottom: 2px solid get-color(primary);
}

.card
{

@include breakpoint(small) {
	width: rem-calc(280);

	.card-section
	{
		padding: 0.5rem;
	}

}//BREAKPOINT

@include breakpoint(large) {
	width: rem-calc(320);

	.card-section
	{
		padding: 0.5rem;
	}

}//BREAKPOINT

@include breakpoint(xlarge) {
	width: rem-calc(390);

	.card-section
	{
		padding: 1rem;
	}
}//BREAKPOINT

	p .size-badge, td .size-badge
	{

		display: inline-block;

		height: 30px;
		width: 30px;

		font-weight: 300;
		text-align: center;
		vertical-align: 0px;

		background-color: get-color(primary);
		color: $white;

		line-height: 1.3;


	}

	// is important for "Zubehör" & "Übersicht" Cards
	// product cars have this sepreatly
	.card-section:last-child
	{
		padding-top: 0px;
		margin-bottom: .15rem;
	}

	.buy-button
	{
	
		font-weight: 300 !important;
		
		svg
		{
			@include transition(all .3s);

			&.down
			{
				margin-left: .75rem;
				@include rotate(90deg, 25%, 45%);	
			}

		}

		&:hover svg
		{
			margin-left: .75rem;
			@include rotate(-90deg, 25%, 62%);					
		}

		&:hover svg.down
		{
			@include rotate(90deg, 25%, 45%);	
		} 

	}

	.buy-option-list
	{

		position: absolute;

		bottom: rem-calc(-239);

		padding-top: 0rem;

		background-color: $white;

		width: 100%;

		z-index: 799;

		ul
		{
			margin-left: 0rem;

			list-style: none;

			li
			{
				padding-top: 0.2rem;
				padding-bottom: 0.2rem;

				a
				{
					font-weight: 300 !important;
				}

			}

		}

		button, div.arrow-button
		{
			width: 100%;
			text-align: left;

			font-weight: 200;

			svg
			{
				margin-top: 0.2rem;
			}

		}

		hr
		{
			margin-top: 0rem;
			//margin-bottom: ;
		}
	}

}

.lipo-heizkoffer.product-page
{

	position: relative;

	display: none;
	opacity: 0;
	
	@include transition(opacity .3s)

	//buy and back button cardsection
	.card-section:last-child
	{
		padding-top: 0px;
		
		z-index: 800;

		background-color: $white;

		margin-bottom: 0rem !important;

		hr
		{
			margin-top: 0rem;
			margin-bottom: rem-calc(23);
		}
	}

	.wrapper
	{

	@include breakpoint(small) {
		margin-left: 0rem;
	}//BREAKPOINT

	@include breakpoint(xlarge) {
		margin-left: rem-calc(50);
	}//BREAKPOINT

	@include breakpoint(xxlarge) {
		margin-left: rem-calc(200);
	}//BREAKPOINT
	
	}

	.navigation
	{

		position: relative;

	@include breakpoint(small) {

		width: rem-calc(340);
		height: rem-calc(600);
		margin-right: rem-calc(20);
	}	
	
	@include breakpoint(large) {
		margin-right: rem-calc(30);

	}
	

	@include breakpoint(xlarge) {

		width: rem-calc(420);
		height: rem-calc(750);
	}

		h2
		{
			font-size: rem-calc(30);
		}

		h2 .size-badge
		{

			display: inline-block;

			height: rem-calc(36);
			width: 	rem-calc(36);

			padding-left: rem-calc(1);

			font-weight: 300;
			text-align: center;
			line-height: 1.15;

			color:$white;
			background-color: get-color(primary);


		}


		.product-image
		{
			position: relative;

		@include breakpoint(small) {
			height: rem-calc(210);	
			}

		@include breakpoint(xlarge) {
			height: rem-calc(250);	
			}

		}

		.page-select
		{

		@include breakpoint(small) {
			//height: rem-calc(220) !important;
			}

		@include breakpoint(xlarge) {
			//height: rem-calc(342) !important;	
			}

		}

		.tabs-title
		{	

			@include transition(margin-left .3s)

			&:hover
			{
				margin-left: .25rem;
			}

			a
			{

				img
				{
					height: 46px;
					width: 46px;
					margin-right: .7rem;
				}

				h4
				{
					display: inline-block;
					margin-bottom: 0px;
					vertical-align: middle;
				}

			}

			@include breakpoint(small) {

				a
				{
					padding-top: 0.2rem;
					padding-bottom: 0.2srem;
				}

			}

			@include breakpoint(xlarge) {

				a
				{
					padding-top: 0.5rem;
					padding-bottom: 0.5rem;
				}

			}

		}

		.arrow-button
		{
			font-weight: 300;
		}


		.card-section:last-child
		{

		@include breakpoint(small) {
			margin-top: rem-calc(0);

			hr
			{
				margin-bottom: rem-calc(10);
			}
			
			button.back
			{
				margin-bottom: 0rem;
				margin-top: rem-calc(5);
			}

		}//BREAKPOINT

		@include breakpoint(xlarge) {
			margin-top: rem-calc(17);

			hr
			{
				margin-bottom: rem-calc(25);
			}
			
			button.back
			{
				margin-bottom: 0rem;
				margin-top: 0rem;
			}

		}//BREAKPOINT

		}

	}

	.tabs-panel
	{

	@include breakpoint(small) {

		width: rem-calc(520);
		height: rem-calc(600);

	}

	@include breakpoint(large) {

		width: rem-calc(600);
		height: rem-calc(600);

	}

	@include breakpoint(xlarge) {

		width: rem-calc(750);
		height: rem-calc(750);

		}
	}

	.tabs-content
	{

	@include breakpoint(small) {

		width: rem-calc(520);
		height: rem-calc(600);

		p, table
		{
			font-size: rem-calc(20);
		}

		.viewport
		{
			width: 100%;
			height: rem-calc(500);

			padding-left: 1.25rem;
			padding-right: 1.25rem;
			padding-top: 1.25rem;
			padding-bottom: 1rem;

			overflow-y: scroll;
			overflow-x: hidden;

			border-top: 1px solid $light-gray;

			hr
			{
				margin-top: 1rem !important;
				margin-bottom: 1rem !important;
			}

			img
			{
				width: 100%;
			}
		}

	}

	@include breakpoint(large) {
		width: rem-calc(600);
		height: rem-calc(600);
	}

	@include breakpoint(xlarge) {

		width: rem-calc(750);
		height: rem-calc(750);

		p, table
		{
			font-size: 1rem;
		}

		.viewport
		{
			height: rem-calc(650);

		}

	}

		border: 1px solid $light-gray;

		.card-section
		{
			padding: 1.25rem;

			h2
			{
				margin-bottom: 1.25rem;
			}
		}


		.page-info
		{
			overflow-y: auto;	
		}

		.page-technischedaten
		{
			overflow-y: auto;	
			
		}

		.page-messdaten
		{
			img
			{
				width: 100%;
			}

			.download
			{
				margin-top: .25rem;
			}

		}

		.page-betriebsanleitung
		{
			padding: 0px;

			h2
			{
				padding: 1.25rem;
				padding-bottom: 0px;
				width: 50%;
			}

			hr
			{
				margin-top: 0px;
				margin-bottom: 0px;
				//margin-left: 1.25rem;
				//margin-right: 1.25rem;
			}

			.download
			{
				padding: 1.5rem;
			}

		}

		.page-sicherheitshinweise
		{
			overflow-y: auto;
		}

	}

	// Utilities /////

	.red-bold
	{
		color:get-color(primary);
		font-weight: 500;
	}

	.image-container // same like viewport (manuel)
	{
		width: 100%;
		height: rem-calc(545);
		overflow: hidden;
	}

	.download
	{

		h4
		{
			display: inline;
			margin-right: .25rem;
			vertical-align: middle;
		}

		svg
		{
			@include transition(all .3s);
		}

		&:hover svg
		{
			margin-left: .25rem;
			@include rotate(90deg, 25%, 50%);				
		}

	}

	.open-gallery
	{

		display: block;
		position: absolute;

		width: 	rem-calc(48);
		height: rem-calc(50);

		padding-left: rem-calc(2);

		top: 0;
		right: 0;

		font-size: rem-calc(60);
		font-weight: 100;

		text-align: center;

		line-height: 0.75;

		&:hover
		{
			background-color: rgba(256, 256, 256, 0.7);

		}
		
	}

}

.gallery-wrapper
{	

	padding: 2rem;

	background-color: rgba(0,0,0,0);
	border: none;

@include breakpoint(small) {
	top: 0rem !important;
	width: rem-calc(800) !important;

	}

@include breakpoint(xlarge) {
	top: 3rem !important;
	width: rem-calc(1200) !important;

	}

	.close-button
	{
		top:	rem-calc(45);	
		right:	rem-calc(60);

		z-index: 999;
	}

	.gallery-stage .content-object img
	{
		position: relative;
		margin-bottom: 2rem;
	}

	.description
	{
		position: absolute;

		//bottom:0;

		padding: 1rem;

		min-height: rem-calc(50);
		width: 100%;
		
		color: $dark-gray;
		//background-color: rgba(256, 256, 256, 0.5);
	}
}

	
#lipo-heizkoffer-info
{

	//opacity: .1;
	//switch opactiy to 1 if this section ist in your visual focus

	//article:first-child
	//article:last-child

@include breakpoint(small) {

	figure
	{
		position: relative;
		padding-top: 2rem;

		img
		{
			@include horizontal-center;
			width: rem-calc(180);
		
		}

		figcaption
		{
			text-justify: center;
			padding-top: rem-calc(180);
		}

	}
}


@include breakpoint(xlarge) {
	
	figure
	{

		img
		{
			width: rem-calc(200);
		}

		figcaption
		{
			padding-top: rem-calc(200);
		}

	}
}
	article
	{
		padding-left: 0rem;
		padding-right: 0rem;
		padding-top: 2rem;
		padding-bottom: 0rem;

	}

	article:last-child
	{
		div:first-child
		{
			padding-left: 0px;
		}

		div:last-child
		{
			padding-right: 0px;
		}
	}


}

#lipo-heizkoffer-uebersicht
{

	@include transition(opacity .3s)

	div.columns
	{
		padding: 0px;
	}


@include breakpoint(small) {
	h2
	{
		margin-bottom: 0.4rem;
	}

	h4
	{
		font-weight: 300;
		font-size: rem-calc(22.4) !important;

		small
		{
			font-weight: 200;
		}
	}

	.lead
	{
		font-weight: 300;
		font-size: rem-calc(22.4) !important;
	}

	hr.red
	{
		margin-top: 	0.5rem;
		margin-bottom: 	1rem;
	}

	.description
	{
		hr:last-child
		{
			margin-top: 1.5rem;
		}
	}

}//BREAKPOINT


@include breakpoint(xlarge) {
	h2
	{
		margin-bottom: 1rem;
	}

	h4
	{
		font-size: rem-calc(27) !important;
	}

	.lead
	{
		font-size: rem-calc(27) !important;
	}

	hr.red
	{
		margin-top: 	0rem;
		margin-bottom: 	1rem;
	}
}//BREAKPOINT

//mobile FIXFIX
@include breakpoint(medium only) {
		
	.description
	{
		height: rem-calc(255);
	}

}//BREAKPOINT


}
