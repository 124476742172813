footer
{
	background-color: rgb(250,250,250);
	color: $medium-gray;

	border-top: 1px solid $light-gray;

	padding-left: 1rem;
	padding-right: 1rem;
	padding-bottom: 3.5rem;

	strong
	{
		font-weight: 500;
		display: inline-block;
		margin-bottom: .5rem;
	}

	a
	{
		color:$medium-gray;

		&:hover
		{
			color: $dark-gray;
		}

		&:visited 
		{
			color: $medium-gray !important;
		}

	}
}


#page-impressum
{

	h2
	{
		font-weight: 500;
		margin-bottom: 2rem;
	}

	strong
	{
		font-weight: 400;
	}

	hr
	{
		margin-top: 3rem;
		margin-bottom: 3rem;
	}

	margin-bottom: 4rem;

	padding-top: 4rem;
	padding-bottom: 4rem;

}