//Latina font family

@include font-face(Latina, 100, normal, '../font/Latina/Thin/',			'Latina-Thin');
@include font-face(Latina, 200, normal, '../font/Latina/Ultralight/',	'Latina-UltraLight');
@include font-face(Latina, 300, normal, '../font/Latina/Light/',		'Latina-Light');
@include font-face(Latina, 400, normal, '../font/Latina/Regular/',		'Latina-Regular');
@include font-face(Latina, 500, normal, '../font/Latina/Medium/',		'Latina-Medium');
@include font-face(Latina, 600, normal, '../font/Latina/Semibold/',		'Latina-SemiBold');
@include font-face(Latina, 700, normal, '../font/Latina/Bold/',			'Latina-Bold');
@include font-face(Latina, 800, normal, '../font/Latina/Ultrabold/',	'Latina-UltraBold');
@include font-face(Latina, 900, normal, '../font/Latina/Heavy/',		'Latina-Heavy');
